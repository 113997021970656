import React from "react";
import {
  SEO,
  MarkdownContent,
  ContactForm,
  createDefaultMessageFromState,
} from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class EventsPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid
          className={"custom-page-caterings custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row className={""} style={{ padding: 16 }} columns={1}>
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "454a68f5-58ff-4fe0-8d3e-a3bca810ed81",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={""}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={""}
              style={{ padding: 16 }}
              width={8}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <ContactForm
                  className={""}
                  style={{}}
                  businessId={1334}
                  businessType={"merchant"}
                  locationId={1400}
                  subheader={"Please provide your details below:"}
                  showLabels={true}
                  emailSubject={"Please provide your details below:"}
                  constructMessage={createDefaultMessageFromState}
                  fields={[
                    {
                      type: "input",
                      label: "name",
                      required: false,
                      inputType: "text",
                      placeholder: "Your Name",
                      displayLabel: "Name",
                      isFieldContactMapped: true,
                    },
                    {
                      type: "input",
                      label: "phone_number",
                      required: false,
                      inputType: "tel",
                      placeholder: "(123) 456-7890",
                      displayLabel: "Phone Number",
                      isFieldContactMapped: true,
                    },
                    {
                      type: "input",
                      label: "email",
                      required: false,
                      inputType: "email",
                      placeholder: "example@gmail.com",
                      displayLabel: "Email",
                      isFieldContactMapped: true,
                    },
                    {
                      type: "input",
                      label: "event_date",
                      required: false,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Event Date:",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "event_time",
                      required: false,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Event Time:",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "event_location",
                      required: false,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Event Location:",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "type_of_event_e_g_wedding_corporate_birthday",
                      required: false,
                      inputType: "text",
                      placeholder: "",
                      displayLabel:
                        "Type of Event (e.g., wedding, corporate, birthday):",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "number_of_guests",
                      required: false,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Number of Guests:",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "event_theme_style",
                      required: false,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Event Theme/Style:",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "meal_service_type_e_g_buffet_plated",
                      required: false,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Meal Service Type (e.g., buffet, plated):",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "dietary_restrictions_allergies",
                      required: false,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Dietary Restrictions/Allergies:",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "textarea",
                      label: "menu_preferences",
                      required: false,
                      inputType: "textarea",
                      placeholder:
                        "Please include your preferred appetizers, main courses, desserts, and beverages.",
                      displayLabel: "Menu Preferences:",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "textarea",
                      label: "additional_services",
                      required: false,
                      inputType: "textarea",
                      placeholder:
                        "Please include your preferred additional services for Cake/Cupcakes, Decor/Theme, and Staffing/Service.",
                      displayLabel: "Additional Services:",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "input",
                      label: "total_budget_for_catering",
                      required: false,
                      inputType: "text",
                      placeholder: "",
                      displayLabel: "Total Budget for Catering:",
                      isFieldContactMapped: false,
                    },
                    {
                      type: "textarea",
                      label: "additional_comments_questions",
                      required: false,
                      inputType: "textarea",
                      placeholder: "",
                      displayLabel: "Additional Comments/Questions:",
                      isFieldContactMapped: false,
                    },
                  ]}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 23403 }) {
      title
      description
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
